import React from "react";
import { MyPatientsMainSection} from "../../components/MyPatientsComponents";

const MyPatients = () => {
  return (
    <div className="w-full h-fit px-2 pt-10 sm:p-10 relative">
      <MyPatientsMainSection />
    </div>
  );
};

export default MyPatients;
