
import { getGlobalAuthState, getGlobalLogOut, setGlobalAuthState } from "../context/useAuth";
import axios from "axios";

const baseURL = process.env.REACT_APP_SERVER_URL;

export const axiosAuthorized = axios.create({
    baseURL: baseURL,
});

export const axiosInstance = axios.create({
    baseURL: baseURL,
});


axiosAuthorized.interceptors.request.use(
    (config) => {
        const { token } = getGlobalAuthState();
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosAuthorized.interceptors.response.use(
    (response) => response,
    (error) => {
        const originalRequest = error.config;
        if (
            error?.response?.status === 403 ||
            error?.response?.data?.message === "Access forbidden, Invalid token"
        ) {
            return axiosInstance
                .post("/doctor_refreshtoken", null, {
                    withCredentials: true,
                })
                .then((response) => {
                    const { user, token } = response?.data?.data;
                    setGlobalAuthState({ user, token });
                    if (originalRequest && originalRequest.headers) {
                        originalRequest.headers[
                            "Authorization"
                        ] = `Bearer ${token}`;
                    }
                    return axiosAuthorized(originalRequest);
                })
                .catch((error) => {
                    const logOut = getGlobalLogOut();
                    logOut && logOut();
                    return Promise.reject(error);
                });
        }
        return Promise.reject(error);
    }
);
