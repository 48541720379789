import React, { useMemo } from 'react'
import { Table } from '../../Common';

const AppointmentsTable = ({ datas, setFollowup, setTest, setPrescription, setUpload }) => {
  const headings = useMemo(
    () => [
      "Name",
      "P-ID",
      "V-ID",
      "Disease",
      "Date",
      "Slot",
      "Time",
      "BookedTime",
      "Follow",
      // "Prescription",
      "Scan & Upload",
      // "Print",
      "Download",
    ],
    []
  );

  // const handlePrint = (data) => {
  //    if (data?.prescription_upload) {
  //      const printWindow = window.open(data.prescription_upload, "_blank");
  //      printWindow.onload = () => {
  //        printWindow.print();
  //      };
  //    }
  // };
  const handleDownload = (data) => {
    if (data?.prescription_upload) {
      const downloadLink = document.createElement("a");
      downloadLink.href = data.prescription_upload;
      downloadLink.download = "prescription.pdf";
      downloadLink.click();
    }
  };
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {data?.patient_id?.name}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.patient_id?.patient_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.visit_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.disease}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">{data?.date}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">{data?.slot}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">{data?.time}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {data?.createdAt
                ? new Date(data.createdAt).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                    timeZone: "UTC",
                  })
                : "N/A"}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <button
              className="flex justify-center items-center gap-2 text-xs border rounded-md bg-blue-600 text-white p-2"
              onClick={() => setFollowup(data)}
            >
              Follow
            </button>
          </td>
          {/* <td className="px-6 py-4 whitespace-nowrap">
            <button
              className="flex justify-center items-center gap-2 text-xs border rounded-md bg-green-600 text-white p-2"
              onClick={() => setPrescription(data)}
            >
              Prescription
            </button>
          </td> */}
          <td className="px-6 py-4 whitespace-nowrap">
            <button
              onClick={() => setUpload(data)}
              className="flex justify-center items-center gap-2 text-xs border rounded-md bg-blue-600 text-white p-2"
            >
              Scan & Upload
            </button>
          </td>
          {/* <td className="px-6 py-4 whitespace-nowrap">
            {data?.prescription_upload ? (
              <button
                onClick={() => {
                  handlePrint(data);
                }}
                className="flex justify-center items-center gap-2 text-xs border rounded-md bg-green-600 text-white p-2"
              >
                Print
              </button>
            ) : (
              <span className="text-xs w-full text-red-600 text-center">
                No Prescription
              </span>
            )}
          </td> */}
          <td className="px-6 py-4 whitespace-nowrap">
            {data?.prescription_upload ? (
              <button
                onClick={() => {
                  handleDownload(data);
                }}
                className="flex justify-center items-center gap-2 text-xs border rounded-md bg-blue-600 text-white p-2"
              >
                Download
              </button>
            ) : (
              <span className="text-xs w-full text-red-600 text-center">
                No Prescription
              </span>
            )}
          </td>
        </tr>
      ))}
    </Table>
  );
}

export default AppointmentsTable