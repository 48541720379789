import { Layout } from './layout';
import { Route, Routes } from 'react-router-dom';
import { Appointments, Login, MyPatients, Profile, Refer } from './pages';
import { AuthProtected, UserProtected } from "./components/ProtectedRoutes/UserProtected";


function App() {
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />} />}>
        <Route index={true} element={<Appointments/>} />
        <Route path='/my-patients' element={<MyPatients/>} />
        <Route path='/refer' element={<Refer/>} />
        <Route path='/profile' element={<Profile/>} />
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login />} />} />
    </Routes>
  );
}

export default App;
