import React, { useCallback, useEffect, useState } from 'react'
import AppointmentsTable from '../AppointmentsTable/AppointmentsTable';
import AppointmentMode from '../AppointmentMode/AppointmentMode';
import { NoDataFound, SearchInput } from '../../Common';
import { getAppointments } from '../../../utils/appointmentsUtils/appointmentsUtils';
import { useAuth } from "../../../context/useAuth";
import { SlCalender } from "react-icons/sl";
import MyPatientsFollowupModal from '../../MyPatientsComponents/MyPatientsTestModal/MyPatientsFollowupModal';
import MyPatientsPrescripitonModal from '../../MyPatientsComponents/MyPatientsTestModal/MyPatientsPrecripitonModal';
import MyPatientsTestModal from '../../MyPatientsComponents/MyPatientsTestModal/MyPatientsTestModal';
import UploadPriscriptionModal from '../../MyPatientsComponents/MyPatientsTestModal/UploadPriscriptionModal';
import ScheduleModal from '../SchedulerModal/ScheduleModal';
import { getSchedule } from '../../../utils/scheduleUtils/sheduleUtils';

const AppointmentsMainSection = () => {
  const user = useAuth().authState.user;
  const [schedule, setSchedule] = useState({});
  const setScheduleFn = useCallback(() => {
    getSchedule(user?._id)
      .then((res) => {
        setSchedule(res?.data?.data?.[0]);
      })
      .catch((err) => console.log(err));
  }, [user?._id]);
  const [selected, setSelected] = useState("New");
  const [appointments, setAppointments] = useState([]);
  const setAppointmentsFn = useCallback(() => {
    getAppointments(user?._id, selected).then(res => {setAppointments(res.data.data)}).catch(err => console.log(err));
  }, [selected, user?._id]);
  useEffect(() => {
    setAppointmentsFn();
    setScheduleFn();
  }, [setAppointmentsFn,setScheduleFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = appointments?.filter((patient) => {
      const nameMatch = patient?.patient_id?.name?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const pIdMatch = patient?.patient_id?.patient_id
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const vIdMatch = patient?.visit_id?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      const diseaseMatch = patient?.disease?.toString()?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      return nameMatch || pIdMatch || diseaseMatch || vIdMatch;
    });
    setFilteredData(filteredData);
  }, [appointments, searchQuery]);
    const [followup, setFollowup] = useState(null);
    const [prescription, setPriscription] = useState(null);
    const [test, setTest] = useState(null);
    const [upload, setUpload] = useState(null);
      const [scheduleOpen, setScheduleOpen] = useState(false);
  return (
    <>
      {followup && (
        <MyPatientsFollowupModal
          followupData={followup}
          setFollowup={setFollowup}
          user={user}
        />
      )}
      {test && (
        <MyPatientsTestModal setTest={setTest} testData={test} user={user} />
      )}
      {prescription && (
        <MyPatientsPrescripitonModal
          prescriptionData={prescription}
          setPriscriptionData={setPriscription}
          setDatasFn={setAppointmentsFn}
          user={user}
        />
      )}
      {upload && (
        <UploadPriscriptionModal
          setUpload={setUpload}
          user={user}
          setDatasFn={setAppointmentsFn}
          upload={upload}
        />
      )}
      {scheduleOpen && (
        <ScheduleModal
          schedule={schedule}
          setScheduleFn={setScheduleFn}
          user={user}
          setOpen={setScheduleOpen}
        />
      )}
      <div className="flex justify-between items-center">
        <div className="flex font-semibold text-lg">Appointments</div>
        <button
          style={{
            background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
          }}
          onClick={() => {
            setScheduleOpen(true);
          }}
          className="gap-2 text-sm text-white rounded-md px-2 py-1 flex justify-center items-center"
        >
          <span>Scheduler</span>
          <SlCalender />
        </button>
      </div>
      <div className="flex items-center justify-center">
        <div className="flex w-full md:w-1/2 ">
          <SearchInput
            placeholder={"Search by Name, Id"}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>
      <div className="flex  w-full h-full mt-5 justify-start items-center ">
        <AppointmentMode selected={selected} setSelected={setSelected} />
      </div>
      {filteredData?.length > 0 ? (
        <AppointmentsTable
          setUpload={setUpload}
          datas={filteredData}
          setFollowup={setFollowup}
          setTest={setTest}
          setPrescription={setPriscription}
        />
      ) : (
        <div className="flex mt-10">
          <NoDataFound data={"appointments"} />
        </div>
      )}
    </>
  );
}

export default AppointmentsMainSection