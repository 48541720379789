import "./navbar.css"
import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { NavDrop } from "./navdrop";
import { useAuth } from "../../context/useAuth";

export const NavBar = () => {
  const links = useMemo(
    () => [
      { to: "/", destination: "Appointments" },
      { to: "/my-patients", destination: "My Patients" },
      { to: "/refer", destination: "Refer" },
    ],
    []
  );
  const user = useAuth().authState.user;
  return (
    <>
      <div className="navbar">
        <div className="logo-container">
          <div className="logo">
            <img src="/assets/images/logo.png" alt="logo" />
          </div>
          {
            <div className="hidden lg:flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3"
                height="67"
                viewBox="0 0 3 67"
                fill="none"
              >
                <ellipse
                  cx="1.5"
                  cy="33.5"
                  rx="1.5"
                  ry="33.5"
                  fill="url(#paint0_radial_81_19)"
                />
                <defs>
                  <radialGradient
                    id="paint0_radial_81_19"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(1.5 33.5) rotate(90) scale(33.5 1.5)"
                  >
                    <stop offset="0.255208" />
                    <stop offset="1" stopOpacity="0" />
                  </radialGradient>
                </defs>
              </svg>
            </div>
          }
        </div>
        <div className="navlinks ">
          {links.map(({ to, destination }, index) => (
            <NavLink to={to} key={index} className="navlink-class">
              <div>{destination}</div>
              <div className="active-dot"></div>
            </NavLink>
          ))}
        </div>
        <div className="earnings-container">
          <NavDrop user={user} links={links} />
        </div>
      </div>
    </>
  );
};

