import { axiosAuthorized } from "../../api/config"

export const getHospitals = async (mode = null) => {
    try {
        let url = `/getallhospitals`
        if (mode === `About`) {
            url = `/hospital/get_hospitalabout`
        }
        const response = await axiosAuthorized.get(url);
        return response;
    } catch (error) {
        throw error;
    }
}


export const addHospital = async (initialState, hospital_picture, profile_image, hospitalId = null) => {
    try {
        const requestData = {
            ...initialState,
            phone_number: Number(initialState.phone_number),
            hospital_picture,
            profile_image
        };

        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const url = hospitalId
            ? `/hospital/update_hospital/${hospitalId}`
            : `/hospital/create_hospital`;

        const response = await axiosAuthorized[hospitalId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteHospital = async (hospitalId) => {
    try {
        const response = await axiosAuthorized.delete(`/hospital/delete_hospital/${hospitalId}`);
        return response;
    } catch (error) {
        throw error;
    }
}