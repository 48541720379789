export const validateTestForm = (initialState,tests, setError) => {

    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    const priorityPattern = /^(?!Select Priority$).+/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;

    const isValidPatientId = /^LPH-Patient-\d+$/.test(initialState.patient_id);
    if (!isValidPatientId) {
        setError({ global: 'Invalid Patient ID format' });
        return false;
    }

    if (!priorityPattern.test(initialState.priority)) {
        setError({ global: "Please Select Priority" });
        return false;
    }

    if (!datePattern.test(initialState.date)) {
        setError({ global: "Invalid Date" });
        return false;
    }

    if (!timePattern.test(initialState.time)) {
        setError({ global: "Invalid Time" });
        return false;
    }

    if (!emailPattern.test(initialState.email)) {
        setError({ global: "Invalid Email" });
        return false;
    }
    
    if (initialState.payment_method === "Select Payment Method") {
        setError({ global: "Please Select Payment Method" });
        return false;
    }

    if (!tests.length) {
        setError({ global: "Add At Least One Test" });
        return false;
    }

    if (!initialState.reasons.trim().length) {
        setError({ global: "Invalid Reasons" });
        return false;
    }

    return true;
};


export const validateFollowupForm = (initialState, setError) => {

    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;

    if (!datePattern.test(initialState.date)) {
        setError({ global: "Invalid Date" });
        return false;
    }

    if (!timePattern.test(initialState.time)) {
        setError({ global: "Invalid Time" });
        return false;
    }

    if (!initialState.reasons.trim().length) {
        setError({ global: "Invalid Reasons" });
        return false;
    }

    return true;
};


