import React, { useState } from 'react';
import { IoIosClose } from "react-icons/io";
import { toast } from "react-toastify";
import TimeInput from "../../Common/FormComponents/TimeInput/TimeInput";
import { useForm } from "../../../hooks";
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { validateScheduleForm } from '../../../utils/scheduleUtils/validateScheduleForm';
import { addSchedule } from '../../../utils/scheduleUtils/sheduleUtils';
import DateObject from 'react-date-object';
import LisOfHospitals from './ListOfHospitals';


const ScheduleModal = ({ setOpen, user, schedule ,setScheduleFn}) => {
  const [selectedDates, setSelectedDates] = useState(schedule?.schedules?.map(data => 
    new DateObject({
        date: data.date,
        format: "MM/DD/YYYY",
        inputFormat: "MM/DD/YYYY",
      })) || []);
   const handleDateChange = (newDates) => {
     setSelectedDates(newDates);
  };
  const convertTo24HourFormat = (timeString) => {
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":");

    
    let adjustedHours = parseInt(hours, 10);

    if (period === "PM" && adjustedHours !== 12) {
      adjustedHours += 12;
    } else if (period === "AM" && adjustedHours === 12) {
      adjustedHours = 0;
    }

    const formattedHours = adjustedHours?.toString()?.padStart(2, "0");
    const formattedMinutes = minutes?.padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  };
  const [err, setErr] = useState({global:""});
  const [isMorning, setIsMorning] = useState(
    schedule?.schedules?.[0]?.morning.length > 0
  );
  const [isAfterNoon, setIsAfterNoon] = useState(
    schedule?.schedules?.[0]?.afternoon.length > 0
  );
  const [isEvening, setIsEvening] = useState(
    schedule?.schedules?.[0]?.evening.length > 0
  );
  const [morningData, handleMData] = useForm({
  startTime: convertTo24HourFormat(schedule?.schedules?.[0]?.morning?.[0]?.time || ""),
  endTime: convertTo24HourFormat(schedule?.schedules?.[0]?.morning?.[schedule?.schedules?.[0]?.morning?.length - 1]?.time || ""),
  });
  const [afterNoonData, handleAData] = useForm({
  startTime: convertTo24HourFormat(schedule?.schedules?.[0]?.afternoon?.[0]?.time || ""),
  endTime: convertTo24HourFormat(schedule?.schedules?.[0]?.afternoon?.[schedule?.schedules?.[0]?.afternoon?.length - 1]?.time || ""),
  });
  const [eveningData, handleEData] = useForm({
  startTime: convertTo24HourFormat(schedule?.schedules?.[0]?.evening?.[0]?.time || ""),
  endTime: convertTo24HourFormat(schedule?.schedules?.[0]?.evening?.[schedule?.schedules?.[0]?.evening?.length - 1]?.time || ""),
  });
  const handleCheckboxChange = (period) => {
    switch (period) {
      case 'morning':
        setIsMorning(!isMorning);
        break;
      case 'afternoon':
        setIsAfterNoon(!isAfterNoon);
        break;
      case 'evening':
        setIsEvening(!isEvening);
        break;
      default:
        break;
    }
  };
  const [morning_hospital, setMorningHospital] = useState(schedule?.morning_hospital || null);
  const [openForMorning,setOpenForMorning] = useState(false);
  const [afternoon_hospital, setAfterNoonHospital] = useState(schedule?.afternoon_hospital || null);
  const [openForAfterNoon, setOpenForAfterNoon] = useState(false);
  const [evening_hospital, setEveningHospital] = useState(schedule?.evening_hospital || null);
   const [openForEvening, setOpenForEvening] = useState(false);
  const [loading, setLoading] = useState(false);
    const validateData = { dates:selectedDates, isMorning, isEvening, isAfterNoon, morningData, eveningData, afterNoonData };
    const handleSubmit = () => {
      if (validateScheduleForm(validateData,morning_hospital,afternoon_hospital, evening_hospital, setErr)) {
        setLoading(true);
        setErr({ global: "" });
        addSchedule(validateData,morning_hospital?._id, afternoon_hospital?._id, evening_hospital?._id, user?._id, schedule?._id)
          .then(res => { setScheduleFn(); setOpen(false); 
            toast(`Schedule ${schedule ? "Edited" : "Added"}`, {
            type: "success",
            autoClose: 3000,
          }); })
          .catch(err => { console.error(err); toast(`Schedule ${schedule ? "Edit Failed" : "Add Failed"}`, {
              type: "error",
              autoClose: 3000,
          }); })
          .finally(() => setLoading(false));
        }
     }
  return (
    <>
      {openForMorning && (
        <LisOfHospitals
          setOpen={setOpenForMorning}
          selected={morning_hospital}
          setSelected={setMorningHospital}
        />
      )}
      {openForAfterNoon && (
        <LisOfHospitals
          setOpen={setOpenForAfterNoon}
          selected={afternoon_hospital}
          setSelected={setAfterNoonHospital}
        />
      )}
      {openForEvening && (
        <LisOfHospitals
          setOpen={setOpenForEvening}
          selected={evening_hospital}
          setSelected={setEveningHospital}
        />
      )}
      <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
        <div
          style={{
            background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
          }}
          className="flex w-full max-w-screen-md overflow-y-auto h-full border shadow-lg  rounded-md gap-5 px-4 pb-4 pt-2 flex-col "
        >
          <div className="flex w-full justify-between h-fit text-sm text-white">
            <span className=" font-semibold">Schedule</span>
            <IoIosClose
              fontSize={22}
              className="cursor-pointer"
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
          <DatePicker
            multiple
            plugins={[<DatePanel />]}
            value={selectedDates}
            onChange={handleDateChange}
          />
          <div className="flex w-full  px-1 gap-10 items-center justify-between">
            <div className="flex gap-2 text-white">
              <input
                type="checkbox"
                checked={isMorning}
                onChange={() => handleCheckboxChange("morning")}
              />
              <div className="flex font-semibold">Morning</div>
            </div>
            <div className="flex gap-5 w-full">
              <TimeInput
                data={morningData}
                handleInput={handleMData}
                name="Start Time"
                formName={"startTime"}
                setError={setErr}
              />
              <TimeInput
                data={morningData}
                handleInput={handleMData}
                name="End Time"
                formName={"endTime"}
                setError={setErr}
              />
            </div>
          </div>
          <div className="flex w-full items-center gap-5 text-white">
            <button
              onClick={() => {
                setErr({ global: "" });
                setOpenForMorning(true);
              }}
              className="flex w-fit items-center px-2 py-1 rounded-md bg-primary text-white"
            >
              Hospital
            </button>
            <span>Hospital: {morning_hospital?.hospital_name || "Not Selected"}</span>
          </div>
          <div className="flex w-full  px-1 gap-6 items-center justify-between">
            <div className="flex gap-2 text-white">
              <input
                type="checkbox"
                checked={isAfterNoon}
                onChange={() => handleCheckboxChange("afternoon")}
              />
              <div className="flex font-semibold">Afternoon</div>
            </div>
            <div className="flex gap-5 w-full">
              <TimeInput
                data={afterNoonData}
                handleInput={handleAData}
                name="Start Time"
                formName={"startTime"}
                setError={setErr}
              />
              <TimeInput
                data={afterNoonData}
                handleInput={handleAData}
                name="End Time"
                formName={"endTime"}
                setError={setErr}
              />
            </div>
          </div>
           <div className="flex w-full items-center gap-5 text-white">
            <button
              onClick={() => {
                setErr({ global: "" });
                setOpenForAfterNoon(true);
              }}
              className="flex w-fit items-center px-2 py-1 rounded-md bg-primary text-white"
            >
              Hospital
            </button>
            <span>Hospital: {afternoon_hospital?.hospital_name || "Not Selected"}</span>
          </div>
          <div className="flex w-full  px-1 gap-10 items-center justify-between">
            <div className="flex gap-2 text-white">
              <input
                type="checkbox"
                checked={isEvening}
                onChange={() => handleCheckboxChange("evening")}
              />
              <div className="flex font-semibold">Evening</div>
            </div>
            <div className="flex gap-5 w-full ">
              <TimeInput
                data={eveningData}
                handleInput={handleEData}
                name="Start Time"
                formName={"startTime"}
                setError={setErr}
              />
              <TimeInput
                data={eveningData}
                handleInput={handleEData}
                name="End Time"
                formName={"endTime"}
                setError={setErr}
              />
            </div>
          </div>
          <div className="flex w-full items-center gap-5 text-white">
            <button
              onClick={() => {
                setErr({ global: "" });
                setOpenForEvening(true);
              }}
              className="flex w-fit items-center px-2 py-1 rounded-md bg-primary text-white"
            >
              Hospital
            </button>
            <span>Hospital: {evening_hospital?.hospital_name || "Not Selected"}</span>
          </div>
          {err.global && (
            <div className="flex w-full justify-center items-center text-red-600 text-sm">
              {err.global}
            </div>
          )}
          <div className="flex w-full gap-5">
            <button
              onClick={() => setOpen(false)}
              className="w-full rounded-md text-primary border-primary border bg-white px-2 py-1"
            >
              Cancel
            </button>
            <button
              onClick={() => handleSubmit()}
              disabled={loading}
              className="w-full rounded-md bg-primary text-white px-2 py-1"
            >
              Schedule
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ScheduleModal