import { axiosInstance } from "../../api/config";
import { generateSchedule } from "./generateTimeSlots";

export const getSchedule = async (doctorId) => {
    try {
        const response = await axiosInstance.get(`/doctorschedule/${doctorId}`);
        return response;
    } catch (error) {
        return error;
    }
}

export const addSchedule = async ({ dates, isMorning, isAfterNoon, isEvening, morningData, afterNoonData, eveningData },morning_hospital,afternoon_hospital,evening_hospital,doctorId,scheduleId=null) => {
    try {
    const schedules = [];
    const changeDates = () => {
        const changedDates = dates?.map(date => new Date(date).toLocaleDateString('en-US'));
        return changedDates;
    }
    const changedDates = changeDates(); 
    for (let i = 0; i < changedDates.length; i++){
        const schedule = generateSchedule(changedDates[i], isMorning, morningData.startTime, morningData.endTime, isAfterNoon, afterNoonData.startTime, afterNoonData.endTime, isEvening, eveningData.startTime, eveningData.endTime);
        schedules.push(schedule);
    };
    const hospitalData = {
        morning_hospital: isMorning ? morning_hospital : null,
        afternoon_hospital: isAfterNoon ? afternoon_hospital : null,
        evening_hospital: isEvening ? evening_hospital : null
    }
    const requestData = {
        doctor_id: doctorId,
        ...hospitalData,
        schedules
    }
    const requestConfig = {
        headers: { "Content-Type": "application/json" },
    };
    const url = scheduleId
        ? `/schedule/update_schedule/${scheduleId}`
            : `/schedule/add_schedule`;
    const response = await axiosInstance[scheduleId ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
    } catch (error) {
        throw error;
    }
}